<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="Notic Not Found" :active.sync="user_not_found">
      <span>Notic record with id: {{ $route.params.ID }} not found. </span>
      <span>
        <span>Check </span>
        <router-link :to="{ name: 'page-user-list' }" class="text-inherit underline">
          All Notic
        </router-link>
      </span>
    </vs-alert>
    <div class="vx-col lg:w-1/2" hidden>
         
          <vs-input v-model="BrokerData.ID" class="w-full" :data-vv-as="$t('ID')" name="ID" :disabled="false" />
        </div>
    <vx-card ref="ownerCard" :title="$t('UserInformation')" class="user-list-filters mb-6" collapse-action>
      <div class="vx-row w-full">
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("NameAr") }}</label>
          <vs-input v-model="BrokerData.NameAR" class="w-full" :data-vv-as="$t('NameAr')" name="NameAr" :disabled="false" />
        </div>
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("AddressAr") }}</label>
          <vs-input v-model="BrokerData.AddressAR" class="w-full" :data-vv-as="$t('AddressAr')" name="AddressAr" :disabled="false" />
        </div>
      </div>
      <div class="vx-row w-full">
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("Phone") }}</label>
          <vs-input v-model="BrokerData.Phone" class="w-full" :data-vv-as="$t('Phone')" name="Phone" :disabled="false" />
        </div>
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("Mobile") }}</label>
          <vs-input v-model="BrokerData.Mobile" class="w-full" :data-vv-as="$t('Mobile')" name="Mobile" :disabled="false" />
        </div>
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("Email") }}</label>
          <vs-input v-model="BrokerData.Email" class="w-full" :data-vv-as="$t('Email')" name="Email" :disabled="false" />
        </div>
        <div class="vx-col lg:w-1/2">
          <label class="text-sm opacity-75">{{ $t("Country") }}</label>
          <v-select 
            v-model="BrokerData.CountryID" 
            :options="Countries" 
            label="NameAR" 
            class="mt-2 w-full" 
            :reduce="country => country.Id"
            name="CountryID"
          />    
            </div>
      </div>

      <!-- <vs-card title="Upload Image">
        <h4>{{ $t("ImageUrl") }}</h4>
        <template v-if="imageURL">
          <div class="img-container w-48 h-48 mx-auto flex items-center justify-center">
            <img :src="baseURL + imageURL" class="w-full h-full object-contain" />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" />
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">
            <i class='bx bx-upload'></i> Update Image
          </vs-button>
          <vs-button type="flat" color="#999" @click="imageURL = null">
            <i class='bx bx-trash'></i> Remove Image
          </vs-button>
        </div>
      </vs-card> -->
      
      <div style="margin: 5px; padding: 3px;">
        <vs-button color="warning" @click="UpdateBroker">
          {{ $t("Save") }}
        </vs-button>
      </div>
    </vx-card>
    
    <br>
    
    <!-- <div class="app">
      <carousel>
        <carousel-slide class="carousel-slider">
          <img :src="baseURL + BrokerData.ImagePath" :alt="slide">
        </carousel-slide>
      </carousel>
    </div> -->
  </div>
</template>

<script>
  import VxCard from "../../components/vx-card/VxCard.vue";
  import { domain } from "@/gloabelConstant.js";
  import moduleUsersManagement from "@/store/UsersManagement/Broker/moduleUsersManagement.js";
  import moduleCountryManagement from "@/store/Country/moduleCountry.js";
  import vSelect from "vue-select";

  export default {
    components: { VxCard, vSelect },
    data() {
      return {
        baseURL: domain,
        user_not_found: false,
        activeTab: 0,
       
        Id: 0,
        Show: false,
        BrokerData: {
          // ID:0,
          // NameAR:"",
          // AddressAR:"",
          // Phone:"",
          // Mobile:"",
          // Email:"",
          // CountryID:0
        },
      };
    },
    computed: {
      Countries() {
        return this.$store.state.CountryManagement.countries;
      },
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString(); // Adjust format as needed
      },
      addImages() {
        this.$store
          .dispatch("UpdateUsersManagement/addItem", this.imageURL)
          .then(() => {
            window.showAddSuccess();
            this.initValues();
            this.$vs.loading.close();
            this.cancel();
          })
          .catch((err) => {
            if (err.response.status == 400) {
              window.showAddFailed(err.response.data.message);
            } else {
              window.showAddFailed("");
            }
            this.$vs.loading.close();
          });
        this.$store.dispatch("UpdateUsersManagement/fetchDataListItems");
      },
      reset_data() {
        this.initValues();
      },
      initValues() {
        this.imageURL = "";
      },
      openConfirm(data) {
        this.rowDataForDelete = data;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirm`,
          text: this.$t("DeleteConfirmText"),
          accept: this.acceptAlert,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      },
      acceptAlert() {
        this.deleteData(this.rowDataForDelete);
        this.$vs.notify({
          color: "success",
          title: "Deleted record",
          text: this.$t("DeletedSuccessfully"),
        });
      },
      deleteData(data) {
        this.$store.dispatch("UpdateUsersManagement/removeItem", data).catch((err) => {
          console.error(err);
        });
      },
      async updateCurrImg(input) {
        this.$vs.loading();
        if (input.target.files && input.target.files[0]) {
          const file = input.target.files[0];
          this.$store
            .dispatch("UpdateUsersManagement/UploadImage", file)
            .then((res) => {
              if (res.status == 200) {
                this.imageURL = res.data;
                this.successUpload();
                this.$vs.loading.close();
              }
              this.$vs.loading.close();
            })
            .catch(() => {
              this.$vs.loading.close();
              window.showError();
            });
        }
      },
      successUpload() {
        this.$vs.notify({
          color: "success",
          title: "Upload Success",
          text: "Image uploaded Success",
        });
      },
      cancel() {
        this.$router.go(-1);
      },
      UpdateBroker() {

        debugger;
        try {
          const result = this.$validator.validateAll();
          if (result) {
           
            const BrokerDto=this.BrokerData;
            this.$store
              .dispatch("UpdateUsersManagement/UpdateBroker", BrokerDto).then(() => {
              this.$store.dispatch("UpdateUsersManagement/fetchDataListItems");})
              this.cancel();
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
    created() {
      debugger;
      if (!moduleUsersManagement.isRegistered) {
        this.$store.registerModule("UpdateUsersManagement", moduleUsersManagement);
        moduleUsersManagement.isRegistered = true;
      }
      if (!moduleCountryManagement.isRegistered) {
        this.$store.registerModule("CountryManagement", moduleCountryManagement);
        moduleCountryManagement.isRegistered = true;
      }
      this.$store.dispatch("CountryManagement/fetchDataListItems");
       debugger;
      const x = this.$route.params.Id;
      this.$store.dispatch("UpdateUsersManagement/GetBrokerModel", x).then((res) => {
        this.BrokerData = res.data.data;
      })
    },
    mounted() {
      this.isMounted = true;
    },
  };
</script>
  
  <style>
  /* Add any additional styling here */
  </style>
  